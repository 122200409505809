import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import router from './router'
const fb = require('../config/firebase.js')

Vue.prototype.$db = fb.db

Vue.config.productionTip = false

Vue.use(VueMeta)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
