import firebase from 'firebase/app'
import 'firebase/firestore'

const config = {
  apiKey: process.env.VUE_APP_FB_KEY,
  projectId: process.env.VUE_APP_FB_ID
}

firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()

db.enablePersistence()

// firebase collections
const postsCollection = db.collection('posts')

export {
  db,
  postsCollection
}
