<template>
  <div class="container mx-auto px-6 h-screen flex flex-col items-center">
    <div class="flex-1 mb-auto flex flex-col items-center justify-center text-white text-center">
      <h1 class="font-roboto-slab text-white md:text-6xl text-3xl text-center leading-tight mb-6">Kasutustingimused:</h1>
      <p>Sõdurinimegeneraatori veebilehe kasutajaid ei tuvastata ja samalt IP-aadressilt tulnud erinevaid veebilehekülastusi profileerimiseks kokku ei viida.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  metaInfo () {
    return {
      title: 'Mis on sinu sõdurinimi?',
      meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {
          key: 'og:title',
          property: 'og:title',
          content: 'Mis on sinu sõdurinimi? '
        },
        {
          key: 'og:description',
          property: 'og:description',
          content: 'Väge täis sõdurinimed ei ole ainult filmikangelaste jaoks – kliki lingile ja saad endale ka!'
        },
        {
          name: 'image',
          key: 'og:image',
          property: 'og:image',
          content: process.env.VUE_APP_BASE_URL+'defaultOG.png'
        },
        {
          key: 'og:image:type',
          property: 'og:image:type',
          content: 'png'
        },
        {
          key: 'og:image:width',
          property: 'og:image:width',
          content: '1200px'
        },
        {
          key: 'og:image:height',
          property: 'og:image:height',
          content: '628px'
        },
        {
          key: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          key: 'fb:app_id',
          property: 'fb:app_id',
          content: '656371908397150'
        }
      ]
    }
  }
}
</script>
